import React from "react";

import BlockContent from "@sanity/block-content-to-react";
import { graphql } from "gatsby";
import SanityImage from "gatsby-plugin-sanity-image";
import PropTypes from "prop-types";

import AllComponents from "../templates/content/AllComponents";
import Header from "../templates/layouts/Header";
import MainLayout from "../templates/layouts/MainLayout";
import Seo from "../templates/Seo/Seo";

const PageNotFound = ({ data: { page } }) => {
  const bgImage = `linear-gradient(0deg, rgba(18,18,30,1) 0%, rgba(18,18,30,.32) 49%, rgba(18,18,30,1) 100%), url(${page.headerimage?.asset?.url})`;

  return (
    <MainLayout
      header={(
        <Header
          variant="blank"
          theme="dark"
          bgImageUrl={bgImage}
          headline={page.headline}
          text={
            <BlockContent blocks={page._rawDescription} />
          }
          logo={page.icon && (
            <SanityImage
              {...page.icon}
              alt={page.title}
            />
          )}
        />
      )}
    >
      {page.contentElements && (
        <AllComponents elements={page.contentElements} />
      )}
    </MainLayout>
  );
};

export const query = graphql`
   {
    page: sanityAllComponentsPage(_id: {eq: "da7caa72-0e52-415a-815d-3b1e15567b97"}) {

      id
      Metadata {
        ...MetaData
      }
      title
      variant
      slug {
        current
      }
      headerimage {
        asset {
          url
        }
      }
      headline
      _rawDescription
      contentElements {
        ... on SanitySectionHeader {
          _key
          _type
          descriptionText
          headline
          color
          sectionLabel
          showCTA
          sectionID
          button {
            variant
            size
            color
            title
            icon
            internalLink {
              ... on SanityServiceDetail {
                id
                slug {
                  current
                }
              }
              ... on SanityServiceOverview {
                id
                slug {
                  current
                }
              }
              ... on SanitySolutionDetail {
                id
                slug {
                  current
                }
              }
              ... on SanitySolutionOverview {
                id
                slug {
                  current
                }
              }
              ... on SanityLegalDocument {
                id
                slug {
                  current
                }
              }
              ... on SanityService {
                id
                name
                variant
              }
            }
          }
        }
        ... on SanityServiceCardList {
          _key
          _type
          serviceCardList {
            background
            _rawDescription
            label
            variant
            button {
              variant
              size
              color
              title
              icon
              internalLink {
                ... on SanityServiceDetail {
                  id
                  slug {
                    current
                  }
                }
                ... on SanityLegalDocument {
                  id
                  slug {
                    current
                  }
                }
                ... on SanityService {
                  id
                  name
                  variant
                }
              }
            }
            umbrellaContent {
              label
              tags {
                tags {
                  url
                  label
                }
                useJumpmarks
              }
            }
          }
        }
        ... on SanitySolutionCardList {
          _key
          _type
          showAs
          cardSize
          imagePosition
          solutionCardList {
            headline
            label
            background
            showButton
            image {
              ...ImageWithPreview
            }
            logo {
              ...ImageWithPreview
            }
            services {
              name
              variant
              lead {
                image {
                  ...ImageWithPreview
                }
                mail
                name
                telephone
                id
              }
            }
            button {
              variant
              size
              color
              title
              internalLink {
                ... on SanitySolutionDetail {
                  id
                  slug {
                    current
                  }
                }
                ... on SanityServiceDetail {
                  id
                  slug {
                    current
                  }
                }
                ... on SanityLegalDocument {
                  _type
                  id
                  slug {
                    current
                  }
                }
                ... on SanityService {
                  id
                  name
                  _type
                }
              }
            }
          }
        }
        ... on SanityOfficeCardList {
          _key
          _type
          officeCardList {
            areaTitle
            headline
            _rawAddress
            icon {
              ...ImageWithPreview
            }
            services {
              name
              variant
              lead {
                image {
                  _key
                  _type
                  _rawAsset
                  _rawHotspot
                  _rawCrop
                }
                mail
                name
                telephone
                id
              }
            }
          }
        }
        ... on SanityJobCardList {
          _key
          _type
          jobCardList {
            title
            location
            button {
              variant
              size
              color
              title
              internalLink {
                ... on SanityAllComponentsPage {
                  _type
                  id
                  slug {
                    current
                  }
                }
                ... on SanityLegalDocument {
                  _type
                  id
                  slug {
                    current
                  }
                }
                ... on SanityServiceDetail {
                  id
                  slug {
                    current
                  }
                }
                ... on SanityService {
                  id
                  name
                  _type
                }
              }
            }
            services {
              name
              variant
              lead {
                image {
                  _key
                  _type
                  _rawAsset
                  _rawHotspot
                  _rawCrop
                }
                mail
                name
                telephone
                id
              }
            }
          }
        }
        ... on SanityContentCardList {
          _key
          _type
          variant
          sectionID
          initiallyOpen
          contentCardList {
            headline
            variant
            _rawDescription
            icon
            showRelatedLinks
            showPrimaryCTA
            primaryCTA {
              variant
              size
              color
              title
              icon
              external
              internalLink {
                ... on SanityLegalDocument {
                  _type
                  id
                  slug {
                    current
                  }
                }
                ... on SanityServiceDetail {
                  id
                  slug {
                    current
                  }
                }
                ... on SanityService {
                  id
                  name
                  _type
                }
              }
            }
            showSecondaryCTA
            secondaryCTA {
              variant
              size
              color
              title
              icon
              external
              internalLink {
                ... on SanityLegalDocument {
                  _type
                  id
                  slug {
                    current
                  }
                }
                ... on SanityServiceDetail {
                  id
                  slug {
                    current
                  }
                }
                ... on SanityService {
                  id
                  name
                  _type
                }
              }
            }
            relatedLinks {
              ... on SanityLegalDocument {
                id
                _type
                slug {
                  current
                }
                title
              }
              ... on SanityService {
                _type
                id
                name
                variant
              }
            }
            overline {
              label
              variant
            }
            _rawDescription
          }
        }
        ... on SanityCTACard {
          _key
          _type
          sectionID
          image {
            asset {
              url
            }
          }
          label
          headline
          icon
          _rawDescription
        }
        ... on SanityContentSection {
          _key
          _type 
          color
          contentSections {
            _type
            headline
            variant
            button {
              variant
              size
              color
              title
              internalLink {
                ... on SanityLegalDocument {
                  _type
                  id
                  slug {
                    current
                  }
                }
                ... on SanityServiceDetail {
                  id
                  slug {
                    current
                  }
                }
                ... on SanityService {
                  id
                  name
                  _type
                }
              }
            }
            _rawDescription
            label
            image {
              ...ImageWithPreview
            }
            position
            relatedLinks {
              ... on SanityService {
                id
                name
                _type
              }
              ... on SanityHomepage {
                id
                slug {
                  current
                }
                title
              }
              ... on SanityLegalDocument {
                id
                title
                slug {
                  current
                }
              }
            }
            umbrellaLabel
            variant
          }
        }
        ... on SanityContactSection {
          _key
          _type 
          title
          color
        }  
        ... on SanityContentModuleEmbed {
          _key
          _type
          headline
          icon
          color
          label
          position
          image {
            ...ImageWithPreview
          }
          button {
            variant
            size
            color
            title
            internalLink {
              ... on SanityLegalDocument {
                _type
                id
                slug {
                  current
                }
              }
              ... on SanityServiceDetail {
                id
                slug {
                  current
                }
              }
              ... on SanityService {
                id
                name
                _type
              }
            }
          }
          relatedLinks {
            ... on SanityService {
              id
              name
              _type
            }
            ... on SanityHomepage {
              id
              slug {
                current
              }
              title
            }
            ... on SanityLegalDocument {
              id
              title
              slug {
                current
              }
            }
          }
          _rawDescription
        }
      }
    }
    
 }
`;

PageNotFound.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

const seo = ({ data }) => (
  <Seo data={data.page?.Metadata} />
);

seo.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export const Head = seo;

export default PageNotFound;
